import { ReactComponent as SvgArrow } from '@img/icons/navigation-arrow.svg';
import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import styled, { css } from 'styled-components';
const Container = styled.nav `
  background: #fff;
  flex-direction: column;
  margin: 0;
  padding: 20px 0px 0px 0px;
  display: flex;

  @media ${from(Device.DesktopSmall)} {
    display: none;
  }
`;
const BackButton = styled.button.attrs({ type: 'button' }) `
  display: flex;
  align-items: center;
  background: none;
  border: 0;
  color: #000000;
  ${fonts.DaxPro.Regular}
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  margin: 0 0 16px;
  padding: 0 10px;
  text-align: left;

  &:focus,
  &:hover {
    background: none;
    border: 0;
    color: #000000;
  }
`;
const BackArrow = styled(SvgArrow) `
  display: inline-block;
  margin: 0 16px 0 0;
  transform: translateY(-1px) rotate(180deg);
`;
const Title = styled.a `
  display: block;
  color: #000000;
  ${fonts.DaxPro.Bold}
  font-size: 18px;
  line-height: 22px;
  padding: 0 10px 18px;
  text-align: left;
`;
const Item = styled.div `
  display: block;
  padding: 0;
  margin: 0 0 3px;
  overflow: hidden;
  flex-shrink: 0;
`;
const linkStyles = css `
  display: flex;
  align-items: center;
  background: #f6f6f6;
  border: 0;
  color: #000000;
  ${fonts.DaxPro.Regular}
  font-size: 16px;
  height: 40px;
  line-height: 16px;
  margin: 0;
  min-width: 0;
  padding: 0 10px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &:focus,
  &:hover {
    background: #f6f6f6;
    border: 0;
    color: #000000;
  }
`;
const ItemButton = styled.button.attrs({ type: 'button' }) `
  ${linkStyles}

  [data-use-yoursolution-theme='true'] & {
    padding: 0;
  }
`;
const ItemLink = styled.a `
  ${linkStyles}
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
`;
const ItemArrow = styled(SvgArrow) `
  margin-left: auto;

  [data-use-yoursolution-theme='true'] & {
    display: none;
  }
`;
export default {
    BackArrow,
    BackButton,
    Container,
    Item,
    ItemArrow,
    ItemButton,
    ItemLink,
    Title,
};
