import { ReactComponent as SvgYourSolution } from '@img/icons/yoursolution.svg';
import { Device, until } from '@helpers/media';
import srOnly from '@helpers/srOnly';
import styled from 'styled-components';
import { ButtonReset } from '@helpers/global';
const Container = styled.a.attrs({ type: 'button' }) `
  ${ButtonReset};
  display: flex;
  align-content: center;
  align-items: center;
  background: #6f9d20;
  color: #333333;
  height: 100%;
  padding: 0 22px;
  justify-content: center;
  justify-items: center;

  @supports (display: grid) {
    display: grid;
  }

  &:focus {
    color: #333333;
    outline: auto !important;
  }

  &:hover {
    color: #ffffff;
    background-color: #a2b952;
  }

  @media ${until(Device.DesktopSmall)} {
    display: flex;
    height: 42px;
    padding: 10px 10px 12px;
    width: 100%;
    justify-content: flex-start;
  }
`;
const Logo = styled(SvgYourSolution) `
  display: block;
  height: 20px;
  width: 135px;
`;
const Label = styled.span `
  ${srOnly}
`;
export default {
    Container,
    Label,
    Logo,
};
