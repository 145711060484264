import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import { ButtonStyles } from '@stories/Components/Buttons/Button/Button.styles';
import styled from 'styled-components';
const Container = styled.div `
  grid-column: 1 / -1;

  padding: 0px;
  gap: 10px;

  @media ${until(Device.TabletLarge)} {
    display: flex;
    flex-direction: column;

    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }

  @media ${from(Device.Tablet)} {
    padding: 35px 0px;
    gap: 30px;
  }

  @media ${from(Device.TabletLarge)} {
    padding: 62px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
const Callout = styled.div `
  background-color: ${brand.grey.grey96};
  // prettier-ignore
  padding: 6px 10px;
  height: 100%;

  @media ${from(Device.Tablet)} {
    padding: 38px;
  }
`;
const CalloutInner = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 527px;
  height: 100%;
  margin: 0 auto;

  gap: 2px;

  img {
    max-width: 173px;
    max-height: 63px;

    aspect-ratio: 173 / 63;

    @media ${from(Device.Tablet)} {
      max-width: 249px;
      max-height: 92px;
    }

    @media ${from(Device.TabletLarge)} {
      aspect-ratio: 249 / 92;
    }
  }

  @media ${from(Device.Tablet)} {
    gap: 8px;
  }
`;
const Title = styled.span `
  ${fonts.DaxPro.Bold};
  padding: 6px;
  font-size: 16px;
  line-height: 20px;

  @media ${from(Device.Tablet)} {
    padding: 0px 18px;
    font-size: 18px;
  }
`;
const Intro = styled.span `
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  line-height: 20px;
  text-wrap: pretty;
`;
const CTA = styled.div `
  margin-top: auto;

  @media ${until(Device.TabletLarge)} {
    padding: 10px 0px 15px;
  }

  ${ButtonStyles.Button} {
    min-width: 132px;
  }
`;
export const YourSolutionNavigationStyles = {
    Container,
    Callout,
    Title,
    CalloutInner,
    Intro,
    CTA,
};
