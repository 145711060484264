import React from 'react';
import { YourSolutionNavigationStyles as S } from './YourSolutionNavigation.styles';
import YSLogo from '@img/ysp/ys-strapline.png';
import YSPLogo from '@img/ysp/ysp-strapline.png';
import Button from '@stories/Components/Buttons/Button/Button';
const YourSolutionNavigation = (props) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.Callout, null,
            React.createElement(S.CalloutInner, null,
                React.createElement("img", { src: YSLogo }),
                React.createElement(S.Title, null, props.ysTitle),
                React.createElement(S.Intro, null, props.ysIntro),
                React.createElement(S.CTA, null,
                    React.createElement(Button, { branding: "primaryAlt", title: props.ysButtonText, onClick: props.onYsButtonClick })))),
        React.createElement(S.Callout, null,
            React.createElement(S.CalloutInner, null,
                React.createElement("img", { src: YSPLogo }),
                React.createElement(S.Title, null, props.yspTitle),
                React.createElement(S.Intro, null, props.yspIntro),
                React.createElement(S.CTA, null,
                    React.createElement(Button, { branding: "primaryAlt", title: props.yspButtonText, onClick: props.onYspButtonClick }))))));
};
export default YourSolutionNavigation;
