import React from 'react';
import { NavigationDropdownMode } from '../Navigation/Navigation.types';
import S from './NavigationMobile.styles';
import YourSolutionCta from '../YourSolutionCta/YourSolutionCta';
import YourSolutionNavigation from '../Navigation/components/YourSolutionNavigation/YourSolutionNavigation';
const NavigationMobile = ({ activeId, items = [], isPortalHeader = false, onItemClick, onYsButtonClick, onYspButtonClick, }) => {
    const flattened = flatMapRecursive(items, (item) => item.children ?? []);
    const activeItem = flattened.find((item) => item.id === activeId);
    const activeItemChildren = activeItem?.children;
    const activeItemParent = flattened.find((item) => item.children?.find((child) => child.id === activeId));
    const activeItemIsYourSolution = activeItem?.dropdown === NavigationDropdownMode.YourSolution;
    return (React.createElement(S.Container, { key: activeItem?.id ?? '0' },
        activeItem && (React.createElement(React.Fragment, null,
            React.createElement(S.BackButton, { onClick: (event) => {
                    event.preventDefault();
                    onItemClick(activeItemParent?.id ?? '');
                } },
                React.createElement(S.BackArrow, null),
                activeItemParent?.title ? `Back to ${activeItemParent?.title}` : 'Back'),
            React.createElement(S.Title, { href: activeItem.url }, activeItem.title))),
        activeItemIsYourSolution && (React.createElement(React.Fragment, null, activeItem?.yourSolutionNavigationProps && (React.createElement(YourSolutionNavigation, { ...activeItem.yourSolutionNavigationProps, onYsButtonClick: onYsButtonClick, onYspButtonClick: onYspButtonClick })))),
        !activeItemIsYourSolution &&
            (activeItemChildren ?? items).map((item) => (React.createElement(S.Item, { "data-use-yoursolution-theme": item.dropdown === NavigationDropdownMode.YourSolution, key: item.id }, (item.children?.length || item.dropdown === NavigationDropdownMode.YourSolution) &&
                !isPortalHeader ? (React.createElement(S.ItemButton, { onClick: (event) => {
                    event.preventDefault();
                    onItemClick(item.id);
                } },
                item.dropdown === NavigationDropdownMode.YourSolution ? (React.createElement(YourSolutionCta, null)) : (item.title),
                React.createElement(S.ItemArrow, null))) : (React.createElement(S.ItemLink, { isBold: item.style === 'bold', href: item.url }, item.title)))))));
};
export default NavigationMobile;
function flatMapRecursive(array, iteratee) {
    let result = [];
    array.forEach((item) => {
        result = result.concat(item);
        if (Array.isArray(iteratee(item))) {
            result = result.concat(flatMapRecursive(iteratee(item), iteratee));
        }
    });
    return result;
}
