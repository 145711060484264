import React, { useRef, useState } from 'react';
import { NavigationDropdownMode } from '../../Navigation.types';
import S from './NavigationItem.styles';
import YourSolutionCta from '../../../YourSolutionCta/YourSolutionCta';
const NavigationDropdown = React.lazy(() => import('../NavigationDropdown/NavigationDropdown'));
const NavigationItem = ({ children, dropdown, isPortalHeader, promo, title, url, yourSolutionNavigationProps, onYsButtonClick, onYspButtonClick, }) => {
    const [isActive, setIsActive] = useState(false);
    const timeoutRef = useRef(-1);
    return (React.createElement(S.Item, { "data-use-yoursolution-theme": dropdown === NavigationDropdownMode.YourSolution, onMouseEnter: () => {
            if (timeoutRef.current === -1) {
                timeoutRef.current = window.setTimeout(() => setIsActive(true), 100);
            }
        }, onMouseLeave: () => {
            if (timeoutRef.current !== -1) {
                window.clearTimeout(timeoutRef.current);
                timeoutRef.current = -1;
            }
            setIsActive(false);
        }, onKeyDown: (event) => {
            if (event.key.toLowerCase() === 'escape') {
                event.preventDefault();
                event.stopPropagation();
                setIsActive(false);
                return;
            }
        } },
        React.createElement(S.Link, { as: url ? 'a' : 'button', href: url ?? undefined, isActive: isActive, onKeyDown: (event) => {
                if (event.key.toLowerCase() === 'arrowdown') {
                    event.preventDefault();
                    setIsActive(true);
                    return;
                }
                if (event.key.toLowerCase() === 'arrowup') {
                    event.preventDefault();
                    setIsActive(false);
                    return;
                }
            } }, dropdown === NavigationDropdownMode.YourSolution ? React.createElement(YourSolutionCta, null) : title),
        dropdown === NavigationDropdownMode.None || dropdown === undefined || isPortalHeader ? (React.createElement(React.Fragment, null)) : (isActive && (React.createElement(React.Suspense, { fallback: null },
            React.createElement(NavigationDropdown, { isActive: isActive, items: children, mode: dropdown, promo: promo, onYsButtonClick: onYsButtonClick, onYspButtonClick: onYspButtonClick, yourSolutionNavigationProps: yourSolutionNavigationProps
                    ? {
                        ...yourSolutionNavigationProps,
                        onYsButtonClick,
                        onYspButtonClick,
                    }
                    : undefined }))))));
};
export default NavigationItem;
