import React from 'react';
import NavigationItem from './components/NavigationItem/NavigationItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import S from './Navigation.styles';
const Navigation = ({ items = [], homeUrl, isPortalHeader, onYsButtonClick, onYspButtonClick, }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.List, { "data-is-portal": isPortalHeader },
            React.createElement(S.HomeIcon, { href: homeUrl },
                React.createElement(FontAwesomeIcon, { icon: faHome, style: { height: '20px' } })),
            React.createElement(S.Spacer, null),
            items.map((item) => (React.createElement(NavigationItem, { key: item.id, ...item, isPortalHeader: isPortalHeader ?? false, onYsButtonClick: onYsButtonClick, onYspButtonClick: onYspButtonClick }))))));
};
export default Navigation;
